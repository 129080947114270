<script lang="ts" setup>
import { usePlaybookTourStore } from '#core/composables/usePlaybookTourStore'

const auth = useAuth()
const { project } = storeToRefs(auth)

const activityStore = usePlaybookActivitiesStore()
const playbookTourStore = usePlaybookTourStore()
const { currentTourStep: playbookTourStep, totalTourSteps: playbookTotalTourSteps } = toRefs(playbookTourStore)

const dashboardMetricsRequestParams = computed(() => ({
  path: {
    projectId: project.value!.id,
  },
}))
const dashboardMetricsRequestOpts = computed(() => ({
  lazy: !project.value?.id,
}))

const { data: metrics, loading } = useQuery('/projects/{projectId}/dashboard-metrics', {
  params: dashboardMetricsRequestParams,
  options: dashboardMetricsRequestOpts,
})

const tabs = computed(() => [
  { label: `Playbook - Basic (${activityStore.basicActivitiesCounter})`, to: '/' },
  { label: `Playbook - Advanced (${activityStore.advancedActivitiesCounter})`, to: '/playbook-advanced' },
  // DEV-1310
  // { label: 'Tools & services', to: '/tools' },
  { label: 'Token Settings', to: '/token-settings' },
])

const route = useRoute()
const tab = computed(() => {
  return tabs.value.findIndex(t => t.to === route.path)
})

watch(project, () => {
  if (project.value?.id) {
    activityStore.fetchActivities(project.value.id)
  }
}, { immediate: true })
</script>

<template>
  <div data-layout="core/home">
    <NuxtLoadingIndicator />
    <NuxtLayout name="dashboard">
      <template #header>
        <div class="flex gap-4 items-center">
          <img src="/app/illustrations/dashboard-alt.svg" alt="Dashboard Icon" width="32" height="32">
          <div class="text-xl font-semibold flex-shrink-0">
            Welcome to Forgd
          </div>
        </div>
      </template>
      <div>
        <DashboardMetrics
          :loading="loading"
          :project-id="project!.id"
          :metrics="metrics"
          class="mt-5 mb-10"
        />
        <ActivityDetailsDrawer />
        <div class="relative">
          <UiTooltipTour
            v-model="playbookTourStep"
            :step="1"
            :total-steps="playbookTotalTourSteps"
            :x-offset="140"
            :y-offset="-24"
            class="relative inline"
            placement="right"
          >
            <div class="w-[371px] ">
              <div class="text-base font-semibold mb-3">
                Explore recommended Activities and Tools
              </div>
              <div class="text-sm text-forgd-gray-400 mb-3">
                Learn about our recommended playbook.  Explore tools based on the status of your token that will help you achieve your goals.
              </div>
            </div>
          </UiTooltipTour>
        </div>
        <UiTabs :model-value="tab" :items="tabs" />
        <div>
          <slot />
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>
